<div [ngClass]="customClass" [style.width]="width" class="ups-input">
  @if (label.length > 1) {
    <ups-input-label
      [for]="id"
      [size]="size"
      [label]="label"
      [required]="formControl.hasValidator(Validators.required) || required"
    ></ups-input-label>
  }

  <div class="d-flex align-items-center">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">+998</span>
      </div>
      <input
        (input)="valueChange(value)"
        [(ngModel)]="value"
        [disabled]="disabled"
        [placeholder]="placeholder ? placeholder : '(00) 000 00 00' | translate"
        [readonly]="readonly"
        [showMaskTyped]="true"
        class="form-control"
        mask="(00) 000 00 00"
        type="text"
      />
    </div>
  </div>

  @if (showErrorContent) {
    <div class="error-message label-small-error">
    <span
      *ngIf="showValidationErrors()"
      class="p-error"
    >{{ this.errorMessage() }}</span>
    </div>
  }
</div>
