import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {FormsModule, Validators} from '@angular/forms';
import {BasicFormInputComponent} from '@shared/components/forms/basic/basic-form-input.component';
import {InputLabelComponent} from '@shared/components/forms/components/input-label/input-label.component';

@Component({
  standalone: true,
  selector: 'ups-phone-input',
  templateUrl: './ups-phone-input.component.html',
  styleUrl: './ups-phone-input.component.scss',
  imports: [
    CommonModule,
    NgxMaskDirective,
    TranslateModule,
    FormsModule,
    InputLabelComponent,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class UpsPhoneInputComponent extends BasicFormInputComponent {
  @Input() readonly = false;
  @Input() showErrorContent: boolean = true;

  // override   set value(value: any) {
  //   if (value.startsWith('998') && value.length === 12) {
  //     this._Forvalue = value.slice(3);
  //   }
  //   console.log(this._value);
  // }

  protected readonly Validators = Validators;
}
